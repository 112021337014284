<template>
  <div id="collect">
    <div class="container">
      <div class="list"  v-for="(item, index) in collect" :key="index">
        <div class="name">
           <img class="menus" src="../../assets/image/chanpintu.png" alt srcset>
            <p class="name_select">
              删除
            </p>
        </div>
        <p class="list_label_title">{{item.name}}</p>
        <p class="list_label_price">{{item.dis}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      collect: []
    };
  },
  props: ['collections'],
  mounted () {
    console.log(this.collections)
    this.collect = this.collections
  },
  methods: {
    // 跳转到详情
    goDetailPage() {
      this.$router.push({
        path: "/infoDetail"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: auto;
  .list {
    margin-right: 20px;
    margin-bottom: 30px;
    overflow: hidden;
    width: 176px;
    height: 236px;
    float: left;
    position: relative;
    .name{
      width: 100%;
      position: relative;
      height: 176px;
      .menus{
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      &_select{
        position: absolute;
        bottom: 0;
        height: 36px;
        line-height: 36px;
        text-align: center;
        width: 100%;
        color:#fff;
        background-color: #0db168;
        opacity: 0.4;
      } 
    }
    .list_label_title{
        font-size: 16px;
        color: #333;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 176px;
        padding: 9px 15px;
      }
      .list_label_price{
        font-size: 14px;
        color: #999;
        padding-left: 15px;
      }
  }
  .list:nth-child(5){
    margin-right: 0;
  }
}
</style>