        <template>
        <div class="content">
            <div class="main">
            <div class="main-r">
                <div class="section_main">
                    <p class="section_tit">素材管理</p>
                    <p class="section_pip">您所有订单中上传的素材文件将会保存在此处，素材文件可重复使用，订单上传素材文件仅支持压缩包格式，单个文件上传不超过50M</p>
                    <myfile :collections="collection"></myfile>
                </div>
                <!-- 以上是分页 -->
                <div class="pagination">
                    <div class="block">
                        <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage4"
                        :page-sizes="[100, 200, 300, 400]"
                        :page-size="100"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="400"
                        ></el-pagination>
                    </div>
                <!-- 以上是分页 -->
                </div>
            </div>
            </div>
        </div>
        </template>

        <script>
        import Vue from "vue";
        import Header from "@/components/common/header.vue";
        import Footer from "@/components/common/footer.vue";
        import Aside from "@/components/common/aside.vue";
        import collect from "@/components/common/collect.vue";
        import myfile from "@/components/common/myfile.vue";
        import Swiper from "swiper";
        // import "@/swiper/css/swiper.min.css";

        Vue.filter("fil", function(info) {
        //过滤
        return "共" + info + "件产品"; //把item这个原本的info数据加上件产品返回出去
        });

        export default {
        name: "index",
        components: { Header, Footer, Aside, myfile }, //注册组件
        data() {
            return {
            currentPage4: 2,
            collection: [{
                name: '南京华航商务服务南京华航商务服务南京华航商务服务南京华航商务服务',
                dis: '上传时间：2019-02-10',
                isRes: true,
                img2: '../../assets/image/chanpintu.png'
            },{
                name: '南京华航商务服务南京华航商务服务南京华航商务服务南京华航商务服务',
                dis: '上传时间：2019-02-10',
                isRes: true,
            },{
                name: '南京华航商务服务南京华航商务服务南京华航商务服务南京华航商务服务',
                dis: '上传时间：2019-02-10',
                isRes: true,
            },{
               name: '南京华航商务服务南京华航商务服务南京华航商务服务南京华航商务服务',
                dis: '上传时间：2019-02-10',
                isRes: true,
            },{
                name: '南京华航商务服务南京华航商务服务南京华航商务服务南京华航商务服务',
                dis: '上传时间：2019-02-10',
                isRes: true,
            },{
                name: '南京华航商务服务南京华航商务服务南京华航商务服务南京华航商务服务',
                dis: '上传时间：2019-02-10',
                isRes: true,
            }]
            };
        },
        methods: {
            showCont() {
            this.show == true, (this.shows = false);
            },
            showConts() {
            this.show == false, (this.shows = true);
            },
            handleCheckAllChange() {
            },
            handleClick (){

            },
            handleClick2 () {
            },
            btn1() {
            this.flagSelect = true;
            },
            handleSizeChange(val) {
            //分页
            console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            },
            priceDetail() {
            this.$router.push("/priceDetail"); //调转到比价详情
            }
        },
        mounted() {
            var galleryThumbs = new Swiper(".gallery-thumbs", {
            spaceBetween: 4,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true
            });
            var galleryTop = new Swiper(".gallery-top", {
            spaceBetween: 4,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            thumbs: {
                swiper: galleryThumbs
            }
            });
        }
        };
        </script>

        <style lang="scss" scoped>
        @import "../../../assets/scss/common";
        @mixin icon($m) {
        $url: "../../../assets/image/" + $m;
        @include bgImage($url);
        background-size: contain;
        }
        * {
        margin: 0;
        padding: 0;
        }
        h2,
        ul,
        li {
        margin: 0;
        padding: 0;
        }
        a {
        text-decoration: none;
        }
        li {
        list-style: none;
        }
            // ------以上是头部tab切换导航样式-----
        .content {
        width: 100%;
        height: 1192px;
        background: #f5f5f5;
        font-size: 15px;
        /deep/ .main {
                background: red;
            height: 1129px;
            margin: 0 auto;
            font-size: 15px;
            .main-l {
            width: 181px;
            height: 1129px;
            background: #fff;
            float: left;
            }
            /deep/ .main-r {
            // width: 1000px;
            height: 990px;
            background: #fff;
            padding:20px 30px;
             .section_main {
                height: 90%;
                padding: 29px 18px;
                margin: 0 auto;
                width: 100%;
                max-width: 1000px;
                margin-left: 0;
                .section_tit{
                    color: #333;
                    font-size: 18px;
                    margin-bottom: 18px;
                }
                .section_pip{
                    font-size: 14px;
                    height: 50px;
                    line-height: 50px;
                    background-image: linear-gradient(
                        #e6f7f0,
                        #e6f7f0),
                    linear-gradient(
                        #ff9800,
                        #ff9800);
                    background-blend-mode: normal,
                        normal;
                        padding: 0 18px;
                        margin-bottom: 20px;
                        color: #0db168;
                }
            }
            .pagination {
                display: block;
                width: 537px;
                height: 40px;
                margin: 0 auto;
                margin-top: 60px;
            }
            //以上是分页器样式
            }
        }
        }
        </style>
