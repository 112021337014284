<template>
  <div >

    <div class="container">
      <div  v-for="(item, index) in collection" :key="index">


        <div class="list"  @click="onClick(item,index)">
            <div class="name"><img class="menus handShpae" :src="item.logoUrl | imagePath" alt srcset @click="goshop(item.storeId)"></div>
            <div class="list_center">
            <p class="list_center_name" >
              <span @click="goshop(item.storeId)" class="handShpae">{{item.storeName}}</span>
              <span class="bgSpan"></span>
            </p>
            <p class="list_center_addr">{{item.address}}</p>
            <!-- <p class="list_center_addr">{{}}</p> -->
            <p class="goShop" @click="goshop(item.storeId)">进入店铺</p>
            <!-- <img class="list_center_v" :src="../../assets/image/renzheng.png"/> -->
            </div>
            <div class="list_right">
            <img :src="item.cover1 | imagePath" v-show="item.cover1!=''">
            <img :src="item.cover2 | imagePath" v-show="item.cover1!=''">
            <!-- <img :src="item.img2"> -->
            </div>
            <div class="collect_select" v-show="item.selected">
            </div>
            <p class="collect_select_img" v-show="item.selected"></p>
            <!-- <div class="collect_select" v-show="isIndex==index">
            </div>
            <p class="collect_select_img" v-show="isIndex==index"></p> -->
          </div>


        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arr:[],
      isIndex:"",
      collection:[]
      //  collection: [
      //   {
      //     name: "南京华333航商务服务",
      //     address: "江苏省 南京市 鼓楼区",
      //     dis: "15.2",
      //     isRes: true,
      //     selected: false,
      //     img1: "../../assets/image/chanpintu.png",
      //     img2: "../../assets/image/chanpintu.png"
      //   },
      //   {
      //     name: "南京华航商务服务",
      //     address: "江苏省 南京市 鼓楼区",
      //     dis: "15.2",
      //     isRes: true,
      //     selected: false,
      //     img1: "../../assets/image/chanpintu.png",
      //     img2: "../../assets/image/chanpintu.png"
      //   }
      // ]
    };
  },

  mounted () {
    
  },
  created(){
      this.getList();
      
  },
  watch: {
    // collections (val) {
    //   this.collect = val
    // }
  },
  methods: {
    goshop(storeId){
        this.$router.push({
          name:"shopPage",
          params:{
            id:storeId
          }
        })
    },
     getList(){ //获取收藏列表信息
      this.$http.get('collectStore/findByUserId').then(res => {
        this.collection=res.data.data;
        let arr =this.collection;
        let newArr=[];
        arr.forEach(e => {
          newArr.push(e.id)
        });
        console.log(newArr)
        localStorage.setItem('arr', JSON.stringify(newArr));
        console.log(this.collection)
        // this.store = res.data.data;
      })
    },
    onClick(item,i) {
      this.isIndex=i;
      // this.isSelected=true
      console.log(111,item,!item.selected)
      this.$set(item, 'selected', !item.selected)
      if(item.selected){ //选中了列表店铺
      this.arr.push(item.id)
        // console.log(55555)
      localStorage.setItem('arr', JSON.stringify(this.arr));
}
      else{
        for(let i=0;i<=this.arr.length;i++){
            if(item.id==this.arr[i]){
                this.arr.splice(i,1)
            }
        }
        // this.arr.splice(i,1)
        console.log(this.arr)
        // console.log(this.arr.splice(i,1));
        localStorage.setItem('arr', JSON.stringify(this.arr));
      }
  
      // this.$emit('change', item)
    },
    // 跳转到详情
    goDetailPage() {
      this.$router.push({
        path: "/infoDetail"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  margin-top: 30px;
  // background: #fff;
  height: auto;
  
  .list {
    padding: 20px;
    height: 178px;
    border: solid 1px #e5e5e5;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    >div{
      margin-right: 20px;
    }
    .name{
      display: flex;
      width: 140px;
      height: 140px;
      flex: 0;
      justify-content: center;
      align-items: center;
      img{
        display: block;
        width: 110px;
        height: 110px;
      }
    }
    .collect_select{
      width: 100%;
      height: 178px;
      position: absolute;
      top: 0;
      left:0;
      background: #666;
      opacity: 0.5;
      z-index: 100;
      &_img{
        position: absolute;
        right: 0;
        top:0;
        width: 40px;
        height:40px;
        background: url('../../assets/image/moren.png') no-repeat;
        background-size: 40px 40px;
        z-index: 1200;
      }
    }
    .list_center{
      position: relative;
      width: 220px;
      padding-top: 5px;
      &_name{
        width: 100%;
        color: #333;
        font-size: 18px;
        line-height: 45px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      &_addr{
        width: 100%;
        color: #666;
        font-size: 14px;
        line-height: 28px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        
      }
      .goShop{
        width: 100px;
        height: 30px;
        background-color: #0db168;
        border-radius: 2px;
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-top: 16px;
      }
      &_v{
        display: inline-block;
        width: 16px;
	      height: 18px;
        position: absolute;
        top: -5px;
        right: -10px;
      }

      .list_center_name{
        display: flex;
        .bgSpan{
        display: block;
        width: 100%;
          background: url("../../assets/image/renzheng.png") no-repeat;
          right: 0;
          top: -5px;
          margin-left: 5px;
        }
      }
    }
    .list_right{
      width: 520px;
      height:140px;
      display: flex;
      // background: red;
      img{
        display: block;
        width: 250px;
        height: 140px;
        margin-right: 21px;
        flex-shrink: 0;
      }
    }
  }
}

.handShpae{
  cursor: pointer;
}

</style>